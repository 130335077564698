<template>
  <div id="node">

    <div class="card mb-4">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-5">
          <span v-if="device.type==1">
            <i class="fas fa-solar-panel"></i>
          </span>
          <span v-if="device.type==2">
            <i class="fas fa-charging-station"></i>
          </span>
          Device{{device.number}} <small v-if="device.detail">({{device.detail}})</small>
        </strong>
        <small>
          <span v-if="device.type==1"> (AC Converter) </span>
          <span v-if="device.type==2"> (Inverter) </span>
        </small>
        <hr>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <button type="button" class="button is-small is-primary" style="float:right;" @click="refreshButton">
            <span class="mr-2 is-hidden-mobile">Refresh</span>
            <i class="fas fa-retweet"></i>
          </button>
          <ul>
            <li><a @click="redirect('/member/mornitor')">Plants</a></li>
            <li><a @click="redirect('/member/branch/'+node.b_id)">{{branch.name}}</a></li>
            <li><a @click="redirect('/member/node/'+device.n_id)">{{node.name}} <small class="ml-1 is-size-7">({{node.mac}})</small></a></li>
            <li class="is-active"><a>Device{{device.number}} <small class="ml-1 is-size-7" v-if="device.detail">({{device.detail}})</small></a></li>
          </ul>
        </nav>
        <hr>
      </div>
    </div>
    <div class="mb-4" v-if="parseInt(device.type)==1">
      <div class="columns is-multiline is-touch">
        <div class="column is-half">
          <div class="box">
            <div class="table-container">
              <table class="table is-bordered w-100">
                <thead>
                  <tr class="has-text-centered is-size-7">
                    <th>Phase</th>
                    <th>Volt</th>
                    <th>Current</th>
                    <th>Power</th>
                    <th>Power Factor</th>
                    <th>Frequency</th>
                  </tr>
                </thead>
                <tbody class="has-text-centered is-size-7">
                  <!-- class="has-text-info" -->
                  <tr>
                    <td> <b>A</b> </td>
                    <td>{{latestLog.Volt_a}} V</td>
                    <td>{{latestLog.Current_a}} A</td>
                    <td>{{latestLog.Power_a}} W</td>
                    <td>{{latestLog.PF_a}} </td>
                    <td>{{(parseInt(latestLog.Volt_a)>3)?latestLog.Freq:0}} Hz</td>
                  </tr>
                  <tr :class="{'has-text-grey-lighter':latestLog.Volt_b=='-'}">
                    <td> <b>B</b> </td>
                    <td>{{latestLog.Volt_b}} V</td>
                    <td>{{latestLog.Current_b}} A</td>
                    <td>{{latestLog.Power_b}} W</td>
                    <td>{{latestLog.PF_b}} </td>
                    <td v-if="latestLog.Volt_b=='-'">-</td>
                    <td v-else>{{(parseInt(latestLog.Volt_b)>3)?latestLog.Freq:0}} Hz</td>
                  </tr>
                  <tr :class="{'has-text-grey-lighter':latestLog.Volt_c=='-'}">
                    <td> <b>C</b> </td>
                    <td>{{latestLog.Volt_c}} V</td>
                    <td>{{latestLog.Current_c}} A</td>
                    <td>{{latestLog.Power_c}} W</td>
                    <td>{{latestLog.PF_c}} </td>
                    <td v-if="latestLog.Volt_c=='-'">-</td>
                    <td v-else>{{(parseInt(latestLog.Volt_c)>3)?latestLog.Freq:0}} Hz</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="box" style="height:165px">
            <div class="table-container">
              <table class="table is-bordered w-100">
                <thead>
                  <tr class="has-text-centered is-size-7">
                    <th>Total Power</th>
                    <th>Power Factor</th>
                    <!-- <th>Total Frequency</th> -->
                    <th>Total Energy</th>
                    <th>Img Energy</th>
                    <th>Exp Energy</th>
                  </tr>
                </thead>
                <tbody class="has-text-centered is-size-7">
                  <tr>
                    <td class="has-text-link"> <b>{{(latestLog.Power_t!=="-")?latestLog.Power_t:latestLog.Power_a}} W </b> </td>
                    <td> {{(latestLog.PF_t!=="-")?latestLog.PF_t:latestLog.PF_a}} </td>
                    <!-- <td> {{latestLog.Freq}} Hz </td> -->
                    <td> {{Total_Energy(latestLog)}} Kwh </td>
                    <td> {{latestLog.Imp_Energy}} Kwh </td>
                    <td> {{latestLog.Exp_Energy}} Kwh </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4" v-if="parseInt(device.type)==2">
      <div class="columns is-multiline is-touch">
        <div class="column is-half">
          <div class="box">
            <div class="table-container">
              <table class="table is-bordered w-100">
                <thead>
                  <tr class="has-text-centered is-size-7">
                    <th>PV1-5 voltage</th>
                    <th>PV1-5 current</th>
                    <th>PV1-5 Power</th>
                  </tr>
                </thead>
                <tbody class="has-text-centered is-size-7">
                  <!-- class="has-text-info" -->
                  <tr>
                    <td> <b>A</b> </td>
                    <td> <b>A</b> </td>
                    <td> <b>A</b> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="box">
            <div class="table-container">
              <table class="table is-bordered w-100">
                <thead>
                  <tr class="has-text-centered is-size-7">
                    <th>Output active power</th>
                    <th>Output reactive power</th>
                    <th>Grid frequency</th>
                  </tr>
                </thead>
                <tbody class="has-text-centered is-size-7">
                  <tr>
                    <td> <b>A</b> </td>
                    <td> <b>A</b> </td>
                    <td> <b>A</b> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4">
      <div class="columns is-desktop">
        <div class="column is-full pb-0">
          <button class="button is-small vpdDateButton" @click="previusDate"> <i class="fas fa-caret-left"></i> </button>
          <date-pick ref="date_pick" v-model="inputDate" :inputAttributes="{readonly: true}"></date-pick>
          <button class="button is-small vpdDateButton" @click="nextDate"> <i class="fas fa-caret-right"></i> </button>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column is-full pt-0" v-if="renderChart">
          <div style="width:100%; height:375px;" v-if="!jsonData.length">
            <br><br><br><br>
            <p> <b>Not Found Data</b> </p>
            <small>{{inputDate | moment}}</small>
          </div>
          <highcharts :options="chartData" :date="inputDate" v-else/>
        </div>
        <div class="column is-full py-0" v-else>
          <button class="button is-white is-loading" style="width:100%; height:375px;"></button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Chart from "@/components/Chart";
// https://github.com/highcharts/highcharts-vue
// https://codesandbox.io/s/vue-template-jyp7o?file=/src/App.vue

import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';

export default {
  name: 'Membernode',
  components: { highcharts: Chart, DatePick },
  props: {
    loaded: Object,
    branchs: Array,
    nodes: Array,
    devices: Array,
    device_log: Array,
    client: Object,
  },
  data(){
    return {
      d_id: this.$route.params.id,
      branch: {},
      node: {},
      device: {},
      message: [],
      dataCollection: null,
      modal_device: false,
      renderChart: false,
      // inputDate: new Date().toISOString().slice(0,10),
      inputDate: this.formatDate(new Date()),
      timeData: null,
      jsonData: [],
    }
  },
  created(){
    const INDEX = this.devices.map((d) => parseInt(d.id)).indexOf(parseInt(this.d_id));
    if(INDEX!==-1){
      this.device = this.devices[INDEX];

      const INDEX_N = this.nodes.map((n) => parseInt(n.id)).indexOf(parseInt(this.device.n_id));
      if(INDEX_N!==-1){
        this.node = this.nodes[INDEX_N];

        const INDEX_B = this.branchs.map((b) => parseInt(b.id)).indexOf(parseInt(this.node.b_id));
        this.branch = this.branchs[INDEX_B];
      }else{
        this.redirect("/member/mornitor/")
      }
    }else{
      this.redirect("/member/mornitor/")
    }
  },
  mounted(){
    if(!this.loaded.get_device_log){
      if(this.device.id) this.$emit('get_device_log',this.d_id, this.device.type);
    }else{
      const INDEX_ = this.loaded.get_device_log.map((d_id) => d_id).indexOf(this.d_id);
      if(INDEX_==-1){
        this.$emit('get_device_log',this.d_id, this.device.type);
      }
    }

    if(!parseInt(this.device.enable)){
      if(parseInt(this.device.id)){
        this.$swal({
          title: "Device is disabled!",
          text: "(Mac Address: "+this.node.mac+") \n (Device Number: "+this.device.number+")",
          className: "text-center",
          icon: "error",
        });
      }
    }else{
      if(this.client){
        this.$emit("subscribeMQTT",this.node.mac);
      }else{
        this.$emit("connectMQTT",this.node.mac);
      }
      this.get_day_log();
    }

    var vdpComponent = document.getElementsByClassName('vdpComponent')[0];
    var inputField = vdpComponent.querySelector('input');
    inputField.classList.add("has-text-centered");
    inputField.classList.add("is-small");
    inputField.classList.add("input");
  },
  methods:{
    refreshButton(){
      this.renderChart = false;
      this.get_day_log();
    },
    submit_edit_device(input){
      console.log(input);
    },
    Total_Energy(log){
      const IMP_ENERGY = (log.Imp_Energy!=="-") ? parseFloat(log.Imp_Energy) : parseInt(0);
      const EXP_ENERGY = (log.Exp_Energy!=="-") ? parseFloat(log.Exp_Energy) : parseInt(0);
      return IMP_ENERGY + EXP_ENERGY;
    },
    DateUTC(date_now){
      var d = new Date(parseInt(date_now));
      return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());
    },
    async get_day_log(){
      var url = this.api+'user/get_day_log/'+this.secret;
      var data = new FormData();
      data.set("d_id", this.device.id);
      data.set("date", this.inputDate);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
      .then((response)=>{
        console.log(response.data);
        if(response.data.error==0){
          this.jsonData = response.data.data;
        }
        setTimeout(() => this.renderChart=true, 500);
      })
      .catch((error)=>{
        console.error(error);
      })
    },
    formatDate(date=null) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
    },
    previusDate(){
      var d = new Date( this.inputDate );
      d.setDate(d.getDate() - 1);

      this.inputDate = new Date(d).toISOString().slice(0,10);
    },
    nextDate(){
      var d = new Date( this.inputDate );
      d.setDate(d.getDate() + 1);

      this.inputDate = new Date(d).toISOString().slice(0,10);
    },
  },
  computed:{
    thisDeviceLog(){
      return this.device_log.filter((log) => {
        return parseInt(log.d_id)==parseInt(this.device.id)
      });
    },
    latestLog(){
      let count = this.thisDeviceLog.length;
      if(parseInt(count)){
        return this.thisDeviceLog[0];
      }else {
        return {}
      }
    },
    chartData(){
      // let Total_Eng = [];
      // let Volt_A = [];
      let POWER = [];
      if(this.jsonData.length){
        this.jsonData.forEach((e) => {
          // Total_Eng.unshift([ this.DateUTC(e.date_now), this.Total_Energy(e) ]);
          // Volt_A.unshift([ this.DateUTC(e.date_now), parseInt(e.Volt_a) ]);
          if(e.Power_t!=="-"){
            POWER.unshift([ this.DateUTC(e.date_now), parseInt(e.Power_t) ]);
          }else{
            POWER.unshift([ this.DateUTC(e.date_now), parseInt(e.Power_a) ]);
          }
        });
      }
      return {
        yAxis: {
          title: {
            text: ""
          }
        },
        series: [{
          name: "Power",
          data: POWER,
          // dashStyle: 'longdash'
        }]
        // series: [{
        //   name: "Total Energy",
        //   data: Total_Eng,
        // },{
        //   name: "Volt A",
        //   data: Volt_A,
        // }]
      };
    }
  },
  watch:{
    inputDate(){
      clearTimeout(this.timeData)
      this.jsonData = [];
      this.renderChart = false;
      this.timeData = setTimeout(() => {
        this.get_day_log();
      }, 1000)
    }
  }
}
</script>

<style media="screen">
.vdpComponent.vdpWithInput input{
  padding: 0 !important;
}
.vdpClearInput{
  display: none !important;
}
.vpdDateButton{
  margin-top: 6px !important;
}
</style>

<!--
Operating state

PV1-5 voltage
PV1-5 current
PV1-5 Power
Output active power
Output reactive power
Grid frequency
A-phase voltage
A-phase current
B-phase voltage
B-phase current
C-phase voltage
C-phase current
Total production
Total generation time
Today production
Today generation time
-->
