<template>
  <div>
    <highcharts :options="chartOptions" ref="lineCharts" :constructor-type="'chart'"></highcharts>
    <!-- :constructor-type="'chart'" -->
    <!-- :constructor-type="'stockChart'" -->
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
import { EventBus } from "./../event-bus.js";
import { syncCharts } from "./../sync-charts.js";

stockInit(Highcharts);
syncCharts(Highcharts);

export default {
  props: ["options", "catchLegendEvents", "date"],
  components: {
    highcharts: Chart
  },

  created() {
    EventBus.$on("extremes-changed", props => {
      Highcharts.charts.forEach(chart => {
        if (chart && chart.index !== props.chartId) {
          chart.xAxis[0].setExtremes(props.min, props.max);
        }
      });
    });
  },

  data() {
    return {
      chartOptions: Highcharts.merge(this.options, {
        chart: {
          zoomType: "x",
          // type: "line",
          type: "spline",
          height: 375,
        },
        xAxis: {
          type: "datetime",
          min: this.MinDateUTC(),
          max: this.MaxDateUTC(),
          gridLineWidth: 0,
          minorTicks: true,
          minorGridLineWidth:1,
          minorTickInterval: 80,
        },
        yAxis: {
          min: 0.01,
          // tickInterval: 0.1,
        },
        title: {
          text: null
        },
        legend: {
          // layout: 'vertical',
          align: 'center',
          verticalAlign: 'top'
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              symbol: 'circle'
            },
            lineWidth: 1,
          },
        },
      })
    };
  },
  methods:{
    MinDateUTC(){
      var d = new Date( this.date );
      return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 1);

      // var d = new Date( this.date );
      // d.setDate(d.getDate() - 1);
      // return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 23, 45);
    },
    MaxDateUTC(){
      var d = new Date( this.date );
      return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59);

      // var d = new Date( this.date );
      // d.setDate(d.getDate() + 1);
      // return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 45);
    },
  }
};
</script>

<style media="screen">
text.highcharts-credits{
  display: none !important;
}
.highcharts-range-selector-group{
  display: none !important;
}
</style>
